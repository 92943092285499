import React from "react"
import styled from "styled-components"
import { DIMSUM } from '../styles/colors'
import Header from "./header"
import Footer from "./footer"

const Wrapper = styled.section``;

const StyledHeader = styled(Header)`
    background-color: ${DIMSUM.YELLOW};
`;

const Layout = ({ children, className }) => {
    return(
        <Wrapper
            className={className}
        >
            <StyledHeader />
                {children}
            <Footer />
        </Wrapper>
    );
}

export default Layout